// 导出 connectWebSocket 方法
export function connectWebSocket(url, messageCallback) {
    this.ws = new WebSocket(url)

    this.ws.addEventListener('open', () => {
        this.connectionStatus = '已连接'
    })

    this.ws.addEventListener('message', (event) => {
        if (event.data == '连接成功') {
            messageCallback(event.data) // 调用回调函数，并传入接收到的消息
        } else {
            const msg = JSON.parse(event.data)
            messageCallback(msg) // 调用回调函数，并传入接收到的消息
        }
    })

    this.ws.addEventListener('close', () => {
        this.connectionStatus = '已关闭'
    })
}