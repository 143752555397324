<template>
    <div class="login-vue" :style="bg">
      <div class="container">
        <p class="title">WELCOME</p>
        <div v-if="!showQrCode" class="input-c">
          <Input
            prefix="ios-contact"
            v-model="account"
            placeholder="用户名"
            clearable
          />
          <p class="error">{{ accountError }}</p>
        </div>
        <div v-if="!showQrCode" class="input-c">
          <Input
            type="password"
            v-model="pwd"
            prefix="md-lock"
            placeholder="密码"
            clearable
            @keyup.enter.native="submit"
          />
          <p class="error">{{ pwdError }}</p>
        </div>
        <div v-else class="qr-code-container" >
          <img :src="qrCode" class="qr-code" alt="二维码" @click="scanQRCode()" title="点击刷新二维码"/>
        </div>
        <div class="buttons">
          <Button
            v-if="!showQrCode && !isShowLoading"
            class="submit"
            type="primary"
            @click="submit"
          >
            登录
          </Button>
          <Button
            v-else
            class="qr-login submit"
            type="dashed" ghost
            @click="showLoginForm"
          >
            密码登录
          </Button>
          <Button
            v-if="!showQrCode"
            :loading="isShowLoading"
            class="qr-login submit"
            type="dashed" ghost
            @click="scanQRCode"
          >
            扫码登录
          </Button>
        </div>
        <p class="account">
          <span @click="register">注册账号</span> |
          <span @click="forgetPwd">忘记密码</span>
        </p>
      </div>
    </div>
  </template>

<script>
import { Message } from 'view-design'
import JSEncrypt from 'jsencrypt'
import { login, getQrCode, userInfo } from '@/api/user.js'
import { connectWebSocket } from '../utils/WebSocketUtil'

export default {
    name: 'login',
    data() {
        return {
            userScan: {},
            account: '',
            pwd: '',
            accountError: '',
            pwdError: '',
            isShowLoading: false,
            bg: {},
            showQrCode: false,
            qrCode: '', // 接收二维码图片链接
        }
    },
    created() {
        this.bg.backgroundImage = 'url(' + require('../assets/imgs/bg0' + new Date().getDay() + '.jpg') + ')'
    },
    watch: {
        $route: {
            handler(route) {
                this.redirect = route.query && route.query.redirect
            },
            immediate: true,
        },
    },
    methods: {
        handleMessage(message) {
            // 处理接收到的消息
            if (message.scanId == this.userScan.scanId && message.status == 'CONFIRMED') {
                Message.success({
                    content: '登录成功!',
                })
                localStorage.setItem('token', message.token)
                this.closeWebSocket()
                userInfo({}).then((result) => {
                    localStorage.setItem('currentUser', JSON.stringify(result.retVal))
                    this.$router.push({ path: this.redirect || '/' })
                })
            }
        },
        register() {},
        forgetPwd() {},
        submit() {
            // eslint-disable-next-line max-len
            let pubKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCkPhdjvFe62qpyqA4zHRNJZwln+zgOxKAZ5c2H8TmqAK9XPemyU0UR0sZnefy/8VNaF2qiTepKynZVNgc2glJwDwhW+lUEeqYeT+DIcAdi+yx9MNPhkeMQ+8JJ92kyHio48m+On+NrOFjY1ZMZtQRNeVmeniCK2N30ijjFvT+IAQIDAQAB'
            const encrypt = new JSEncrypt()
            encrypt.setPublicKey(pubKey)
            login({
                username: this.account,
                password: encrypt.encrypt(this.pwd),
                isRemember: false,
            }).then((res) => {
                Message.success({
                    content: res.retVal,
                })
                userInfo({}).then((result) => {
                    localStorage.setItem('currentUser', JSON.stringify(result.retVal))
                    this.$router.push({ path: this.redirect || '/' })
                })
            })
        },
        scanQRCode() {
            getQrCode().then((res) => {
                const userScanDTO = res.retVal
                this.qrCode = 'data:image/png;base64,' + userScanDTO.qrcode
                this.showQrCode = true
                this.userScan = userScanDTO.userScanLogin
                // eslint-disable-next-line max-len
                connectWebSocket.call(this, 'wss://www.bjca.xyz/diary/subWebsocket/' + userScanDTO.userScanLogin.scanId, this.handleMessage)
            })
            // 隐藏用户名和密码输入框
            this.account = ''
            this.pwd = ''
        },
        closeWebSocket() {
            if (this.ws && this.ws.readyState === WebSocket.OPEN) {
                this.ws.close()
            }
        },
        showLoginForm() {
            this.showQrCode = false
            this.closeWebSocket()
        },
    },
}
</script>

  <style>
  .login-vue {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
  .login-vue .container {
    background: rgba(255, 255, 255, 0.5);
    width: 300px;
    text-align: center;
    border-radius: 10px;
    padding: 30px;
  }
  .login-vue .ivu-input {
    background-color: transparent;
    color: #fff;
    outline: #fff;
    border-color: #fff;
  }
  .login-vue ::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: rgba(255, 255, 255, 0.8);
  }
  .login-vue :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: rgba(255, 255, 255, 0.8);
  }
  .login-vue ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: rgba(255, 255, 255, 0.8);
  }
  .login-vue :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgba(255, 255, 255, 0.8);
  }
  .login-vue .title {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .login-vue .input-c {
    margin: auto;
    width: 200px;
  }
  .login-vue .error {
    color: red;
    text-align: left;
    margin: 5px auto;
    font-size: 12px;
    padding-left: 30px;
    height: 20px;
  }
  .login-vue .submit {
    width: 200px;
    margin-top: 10px;
  }
  .login-vue .qr-login {
    width: 150px;
  }
  .login-vue .account {
    margin-top: 30px;
  }
  .login-vue .account span {
    cursor: pointer;
  }
  .login-vue .ivu-icon {
    color: #eee;
  }
  .login-vue .ivu-icon-ios-close-circle {
    color: #777;
  }
  .login-vue .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .login-vue .qr-code-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
  }
  .login-vue .qr-code {
    max-width: 100%;
    max-height: 100%;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer; /* 添加此样式 */
  }
  </style>